.AddTodoListButton {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 10px;
}

@media only screen and (max-width: 600px) {
  .AddTodoListButton {
    width: 100%;
  }
}
